<template>
  <v-sheet class="d-flex align-center">
    <v-sheet>
      {{ team_user.full_name }}
    </v-sheet>
    <v-chip class="ml-3" label outlined>
      {{ team_user.email }}
    </v-chip>
    <v-spacer></v-spacer>
    <v-chip 
      :color="team_user.team_admin ? 'primary' : ''"
      @click="toggleAdminStatus()"
    >
      {{ team_user.team_admin ? 'Admin' : 'User' }}
    </v-chip>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['team_user', 'team_id'],
  methods: {
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    ...mapActions('admin', [
      'updateUserTeam'
    ]),
    toggleAdminStatus() {
      this.updateUserTeam({
        user_team: {
          team_admin: !this.team_user.team_admin,
        },
        email: this.team_user.email,
        team_id: this.team_id
      })
      .then(() => {
        this.success('admin status updated')
      })
      .catch(e => {
        this.error(e)
      })
    }
  }
}
</script>