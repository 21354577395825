<template>
  <v-sheet
    style="cursor: pointer; border: 1px solid grey; border-radius: 5px;"
    :key="team.id"
    @click="show = true"
  >
    <v-row class="d-flex align-center py-6 px-8">
      <v-sheet class="text-button d-flex align-center">
        {{team.team_name}}
      </v-sheet>
      <v-spacer></v-spacer>
      <!-- <v-chip @click.stop class="mr-4">
        <div v-if="showJoinCode">
          {{team.join_code}}
        </div>
        <div v-else>
          ******
        </div>
        <v-icon @click.stop="showJoinCode = !showJoinCode" class="ml-3" v-if="showJoinCode">mdi-eye-off-outline</v-icon>
        <v-icon @click.stop="showJoinCode = !showJoinCode" class="ml-3" v-else>mdi-eye-outline</v-icon>
      </v-chip> -->
      <!-- {{team.id}} -->
      <!-- <v-btn @click="show = true" class="mr-5" small rounded>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn> -->
    </v-row>
    <!-- {{team}} -->




    <v-dialog max-width="800" v-model="show">
      <v-sheet class="pa-8">
        <v-sheet class="text-button">
          {{team.team_name}}
        </v-sheet>
        <v-chip color="" outlined label>
          Team Id: {{ team.id }}
        </v-chip>
        <v-chip color="" outlined class="ml-2" label>
          Created: {{ team.created }}
        </v-chip>
        <v-chip color="" outlined class="ml-2" label>
          Join code: {{ team.join_code }}
        </v-chip>
        <v-sheet class="text-button d-flex align-center mt-10">
          Users
        </v-sheet>
        <v-sheet style="min-height: 100px;">
          <v-sheet v-if="!loading_team_users">
            <league-team-user
              v-for="user in team_users"
              :key="user.id"
              :team_user="user"
              :team_id="team.id"
            ></league-team-user>
          </v-sheet>
          <v-sheet v-else class="pa-5 pt-10 d-flex justify-center">
            <v-progress-circular color="grey" indeterminate />
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeagueTeamUser from './LeagueTeamUser.vue'
export default {
  components: { LeagueTeamUser },
  props: ['team'],
  data: () => ({
    showJoinCode: false,
    show: false
  }),
  mounted() {
    this.getTeamUsers({ id: this.team.id })
  },
  methods: {
    ...mapActions('admin', [
      'getTeamUsers'
    ])
  },
  computed: {
    ...mapGetters('admin', [
      'loading_team_users',
      'team_users'
    ])
  },
  watch: {
    'show': {
      handler(newVal) {
        if(newVal) this.getTeamUsers({ id: this.team.id })
      }
    }
  }
}
</script>