<template>
  <!-- This page contains the real life tiimi teams that are in this league -->
  <v-sheet class="pa-4">
    <v-sheet style="border-left: 3px solid lightgrey; color: grey;" class="pl-4">
      League teams are the Tiimi user teams have been added to the league
    </v-sheet>
    <!-- <v-sheet class="d-flex justify-end">
      <v-btn class="mb-4" outlined small>
        <v-icon>mdi-plus</v-icon>
        Add team
      </v-btn>
    </v-sheet> -->
    <LeagueTeam
      class="mt-2"
      v-for="team in teams"
      :key="team.id"
      :team="team"
    ></LeagueTeam>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeagueTeam from '@/components/tiimi_admin/league/team/LeagueTeam.vue'

export default {
  data: () => ({
    show: false
  }),
  components: { LeagueTeam },
  created() {
    this.initTeams(this.$route.params.league_id)
  },
  methods: {
    ...mapActions('admin', [
      'initTeams'
    ])
  },
  computed: {
    ...mapGetters('admin', [
      'teams'
    ])
  }
}
</script>